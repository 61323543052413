import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";

export default function data() {
  const [passportList, setPassportList] = useState([]);
  const token = localStorage.getItem("token");
  const getPassportList = async () => {
    try {
      console.log("in try", 11);
      const response = await axios.get(
        "https://companyprocess.appworkdemo.com/api/getAllPassports",
        {
          headers: {
            Auth: token,
          },
        }
      );
      if (response?.data?.data) {
        const newData = response.data.data.map((item, index) => {
          return {
            id: item.directorsGovernmentID,
            passportNumber: item.passport,
            dateOfBirth: item.dateOfBirth,
            handleResetPassword: () => handleResetPassword(item),
            handleActiveDeactive: () => handleActiveDeactive(item),
            handleEdit: () => handleEdit(item),
            handleDelete: () => handleDelete(item),
          };
        });
        setPassportList(newData);
      }
    } catch (error) {}
  };

  //...

  const handleResetPassword = (row) => {
    // Implement reset password logic here
    console.log(`Reset password for ${row.fullName}`);
  };

  const handleActiveDeactive = (row) => {
    // Implement active/deactive logic here
    console.log(`Toggle active status for ${row.fullName}`);
  };

  const handleEdit = (row) => {
    // Implement edit logic here
    console.log(`Edit ${row.fullName}`);
  };

  const handleDelete = (row) => {
    // Implement delete logic here
    console.log(`Delete ${row.fullName}`);
  };

  useEffect(() => {
    getPassportList();
  }, []);

  return {
    columns: [
      {
        Header: "User Name",
        accessor: (item) => item?.email || "-",
        width: "10%",
        align: "left",
      },

      {
        Header: "Start Date & Time",
        accessor: (item) => {
          return item?.dateOfBirth ? moment(item.dateOfBirth).format("DD/MM/YYYY") : "-";
        },
        align: "left",
        width: "10%",
      },
      {
        Header: "End Date & Time",
        accessor: (item) => {
          return item?.dateOfBirth ? moment(item.dateOfBirth).format("DD/MM/YYYY") : "-";
        },
        align: "left",
        width: "10%",
      },

      {
        Header: "Total Hours",
        accessor: (item) => item?.lastLoginTime || "-",
        align: "left",
        width: "10%",
      },
      {
        Header: "Status",
        accessor: (item) => item?.status || "-",
        align: "left",
        width: "10%",
      },

      //   {
      //     Header: "Action",
      //     Cell: ({ items }) => {
      //       const { handleResetPassword, handleActiveDeactive, handleEdit, handleDelete } =
      //         row.original;
      //       return (
      //         <div>
      //           <button onClick={handleResetPassword}>Reset Password</button>
      //           <button onClick={handleActiveDeactive}>
      //             {/* {items.original.isActive ? "Deactive" : "Active"} */}
      //           </button>
      //           <button onClick={handleEdit}>Edit</button>
      //           <button onClick={handleDelete}>Delete User</button>
      //         </div>
      //       );
      //     },
      //     align: "left",
      //     width: "10%",
      //   },
    ],

    rows: passportList,
  };
}
