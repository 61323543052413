/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { forwardRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

// Custom styles for MDTypography
import MDTypographyRoot from "components/MDTypography/MDTypographyRoot";
// import MDModalRoot from "./MDModalRoot";
import MDModalRoot from "components/MDModal/MDModalRoot";

// Material Dashboard 2 React contexts
import { useMaterialUIController } from "context";
import { Modal } from "@mui/material";

// const MDModal = forwardRef(({ html, open, ...rest }, ref) => {
const MDModal = forwardRef(({ ...rest }, ref) => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  return (
    //   <MDModalRoot
    //     {...rest}
    //     ref={ref}
    //     ownerState={{
    //       color,
    //       textTransform,
    //       verticalAlign,
    //       fontWeight,
    //       opacity,
    //       textGradient,
    //       darkMode,
    //     }}
    //   >
    //     {children}
    //   </MDModalRoot>
    <MDModalRoot
      style={style}
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      {html}
    </MDModalRoot>
  );
});

// Setting default values for the props of MDTypography
MDModal.defaultProps = {
  color: "dark",
  fontWeight: false,
  textTransform: "none",
  verticalAlign: "unset",
  textGradient: false,
  opacity: 1,
};

// Typechecking props for the MDTypography
MDModal.propTypes = {
  color: PropTypes.oneOf([
    "inherit",
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
    "text",
    "white",
  ]),
  fontWeight: PropTypes.oneOf([false, "light", "regular", "medium", "bold"]),
  textTransform: PropTypes.oneOf(["none", "capitalize", "uppercase", "lowercase"]),
  verticalAlign: PropTypes.oneOf([
    "unset",
    "baseline",
    "sub",
    "super",
    "text-top",
    "text-bottom",
    "middle",
    "top",
    "bottom",
  ]),
  textGradient: PropTypes.bool,
  children: PropTypes.node.isRequired,
  opacity: PropTypes.number,
};

export default MDModal;
