/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { toast } from "react-toastify";
import axios from "axios";
import jsPDF from "jspdf";

function Basic() {
  const [rememberMe, setRememberMe] = useState(false);
  const [passport, setPassport] = useState("");
  const [passportVerify, setPassportVerify] = useState(false);
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [pdfData, setPdfData] = useState({
    title: "My PDF Document",
    content: "This is a sample PDF document.",
  });

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const handleDownloadPdf = () => {
    const doc = new jsPDF();
    doc.text(pdfData.title, 10, 10);
    doc.text(pdfData.content, 10, 20);
    const pdfBlob = doc.output("blob");
    const url = URL.createObjectURL(pdfBlob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "my_pdf_document.pdf";
    a.click();
  };

  const handleVerifyPassport = async () => {
    try {
      const response = await axios.post("https://companyprocessuk.com/api/verify-passport", {
        passport,
      });
      setPassportVerify(true);
      toast.success(response.data.message);
    } catch (error) {
      setPassportVerify(false);
      toast.error(error.response.data.message);
    }
  };

  const handleVerifyDOB = async () => {
    try {
      const response = await axios.post("https://companyprocessuk.com/api/verify-bod", {
        passport,
        dateOfBirth,
      });
      handleDownloadPdf();
      toast.success(response.data.message);
      // setPassport("");
      // setDateOfBirth("");
      // setPassportVerify(false);
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Company Process
          </MDTypography>
          {/* <Grid container spacing={3} justifyContent="center" sx={{ mt: 1, mb: 2 }}>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <FacebookIcon color="inherit" />
              </MDTypography>
            </Grid>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <GitHubIcon color="inherit" />
              </MDTypography>
            </Grid>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <GoogleIcon color="inherit" />
              </MDTypography>
            </Grid>
          </Grid> */}
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox display="flex" alignItems="center" justifyContent="center" ml={-1} mb={2}>
              <MDBox mr={1}>
                <MDInput
                  type="text"
                  label="Passport Number"
                  value={passport}
                  onChange={(e) => {
                    setPassport(e.target.value);
                  }}
                  fullWidth
                  autoComplete="off"
                />
              </MDBox>
              <MDButton
                variant="gradient"
                color={`${passportVerify ? "success" : "info"}`}
                onClick={() => {
                  if (!passportVerify) {
                    handleVerifyPassport();
                  }
                }}
                disabled={!passport}
                mb={3}
              >
                {passportVerify ? "Verified" : "Verify"}
              </MDButton>
            </MDBox>
            {passportVerify && (
              <MDBox mb={2}>
                <MDInput
                  type="date"
                  value={dateOfBirth}
                  onChange={(e) => {
                    setDateOfBirth(e.target.value);
                  }}
                  fullWidth
                />
              </MDBox>
            )}
            {/* <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Remember me
              </MDTypography>
            </MDBox> */}
            <MDBox mt={4} mb={1}>
              <MDButton
                variant="gradient"
                color="info"
                fullWidth
                disabled={!dateOfBirth}
                onClick={handleVerifyDOB}
              >
                Submit
              </MDButton>
            </MDBox>
            {/* <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Don&apos;t have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/authentication/sign-up"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign up
                </MDTypography>
              </MDTypography>
            </MDBox> */}
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
