/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useNavigate } from "react-router-dom";
import "../../add-passport/style.css";
import calanderIc from "../../../assets/images/calendar.svg";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

// import calanderIc from "../../assets/images/calendar.svg";
// import "./style.css";
// const style = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: 400,
//   bgcolor: "background.paper",
//   border: "2px solid #000",
//   boxShadow: 24,
//   p: 4,
// };

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import { toast } from "react-toastify";
import axios from "axios";
import jsPDF from "jspdf";
import { useTable } from "react-table";
import moment from "moment/moment";
import MDModal from "components/MDModal";

function AddUser() {
  const navigate = useNavigate();
  const [passport, setPassport] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [directorsGovernmentID, setDirectorsGovernmentID] = useState("");
  const token = localStorage.getItem("token");
  console.log(passport, directorsGovernmentID, dateOfBirth, 4555);
  const handleSubmit = async () => {
    try {
      const response = await axios.post(
        "https://companyprocess.appworkdemo.com/api/add-passport",
        {
          passport,
          directorsGovernmentID,
          dateOfBirth,
        },
        {
          headers: {
            Auth: token,
          },
        }
      );
      toast.success(response?.data?.message);
      navigate("/passport-list");
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  return (
    <>
      <div>
        <div className="container">
          <div className="pass-wrapper">
            <div className="title-sec">
              <p>Add User</p>
            </div>
            <div className="passport-form-wrapper">
              <div className="form-wrapper">
                <div className="input-wrapper">
                  <input
                    type="text"
                    className="cus-input"
                    placeholder="Full Name"
                    value={passport}
                    onChange={(e) => {
                      setPassport(e.target.value);
                    }}
                    autoComplete="off"
                  />
                </div>

                <div className="input-wrapper">
                  <input
                    type="email"
                    className="cus-input"
                    placeholder="Email"
                    value={directorsGovernmentID}
                    onChange={(e) => {
                      setDirectorsGovernmentID(e.target.value);
                    }}
                    autoComplete="off"
                  />
                </div>
                <div className="input-wrapper">
                  <input
                    type="text"
                    className="cus-input"
                    placeholder="Phone Number"
                    value={directorsGovernmentID}
                    onChange={(e) => {
                      setDirectorsGovernmentID(e.target.value);
                    }}
                    autoComplete="off"
                  />
                </div>
                <div className="datepicker-main">
                  <DatePicker
                    className="datepicker-input"
                    type="date"
                    placeholderText="Date of Birth"
                    selected={dateOfBirth}
                    onChange={(date) => {
                      const formatedDate = moment(date).format("YYYY-MM-DD");
                      setDateOfBirth(formatedDate);
                    }}
                  />

                  <img src={calanderIc} alt="calander" className="calander-ic" />
                </div>
                <div className="input-wrapper">
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel value="female" control={<Radio />} label="Female" />
                    <FormControlLabel value="male" control={<Radio />} label="Male" />
                    <FormControlLabel value="other" control={<Radio />} label="Other" />
                  </RadioGroup>
                </div>
                <div className="input-wrapper">
                  <input
                    type="text"
                    className="cus-input"
                    placeholder="Set Hourly Rates"
                    value={directorsGovernmentID}
                    onChange={(e) => {
                      setDirectorsGovernmentID(e.target.value);
                    }}
                    autoComplete="off"
                  />
                </div>
                <div className="input-wrapper">
                  <input
                    type="text"
                    className="cus-input"
                    placeholder="Set Hourly Rates"
                    value={directorsGovernmentID}
                    onChange={(e) => {
                      setDirectorsGovernmentID(e.target.value);
                    }}
                    autoComplete="off"
                  />
                </div>
                <div className="submit-btn-sec">
                  <button
                    type="button"
                    className="check-btn submit-btn"
                    color="info"
                    disabled={!(dateOfBirth && passport && directorsGovernmentID)}
                    onClick={handleSubmit}
                    style={{ cursor: "pointer" }}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <MDModal
        open={true}
        onClose={() => {}}
        html={
          <MDBox>
            <MDTypography id="modal-modal-title" variant="h6" component="h2">
              Text in a modal
            </MDTypography>
            <MDTypography id="modal-modal-description" sx={{ mt: 2 }}>
              Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
            </MDTypography>
          </MDBox>
        }
      /> */}
    </>
  );
}

export default AddUser;
