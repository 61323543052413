import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";

export default function data() {
  const [passportList, setPassportList] = useState([]);
  const token = localStorage.getItem("token");

  const getPassportList = async () => {
    try {
      console.log("in try", 11);
      const response = await axios.get("https://companyprocessuk.com/api/getAllPassports", {
        headers: {
          Auth: token,
        },
      });
      if (response?.data?.data) {
        const newData = response.data.data.map((item, index) => {
          return {
            id: item.directorsGovernmentID,
            passportNumber: item.passport,
            dateOfBirth: item.dateOfBirth,
          };
        });
        setPassportList(newData);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getPassportList();
  }, []);
  return {
    columns: [
      {
        Header: "Government ID",
        accessor: (item) => item?.id || "-",
        width: "20%",
        align: "left",
      },
      {
        Header: "Company Number",
        accessor: (item) => item?.passportNumber || "-",
        width: "45%",
        align: "left",
      },
      {
        Header: "Date of Birth",
        accessor: (item) => {
          return item?.dateOfBirth ? moment(item.dateOfBirth).format("DD/MM/YYYY") : "-";
        },
        align: "left",
      },
      // { Header: "Directors Government ID", accessor: "id", width: "20%", align: "left" },
      // { Header: "Passport Number", accessor: "passportNumber", width: "45%", align: "left" },
      // { Header: "Date of Birth", accessor: "dateOfBirth", align: "left" },
    ],

    rows: passportList,
  };
}
