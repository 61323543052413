/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import NewDoc from "../../assets/images/NewDoc.pdf";
import calanderIc from "../../assets/images/calendar.svg";
import asset2 from "../../assets/images/Asset.png";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import "../add-passport/style.css";
import "../add-passport/responsive.css";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { toast } from "react-toastify";
import axios from "axios";
import jsPDF from "jspdf";
import { useTable } from "react-table";
import moment from "moment/moment";
import { TextField } from "@mui/material";
// import ReactGA from "react-ga";

import ReactGA from "react-ga4";

function PassportVerify() {
  const [passport, setPassport] = useState("");
  const [passportVerify, setPassportVerify] = useState(false);
  const [dgIdVerify, setDgIdVerify] = useState(false);
  const [bodVerify, setBodVerify] = useState(false);

  useEffect(() => {
    ReactGA.initialize("G-Q969Y53VDJ"); // Replace with your GA4 Measurement ID
  }, []);

  ReactGA.send({
    hitType: "pageview",
    page: "/",
    title: "PassportVerify",
  });
  // useEffect(() => {
  //   // Track page view
  //   ReactGA.pageview(window.location.pathname + window.location.search);
  // }, []);
  // pdf download handler
  const handleDownloadPdf = async () => {
    const pdfUrl = NewDoc;
    const response = await fetch(pdfUrl);
    const pdfBlob = await response.blob();

    const url = URL.createObjectURL(pdfBlob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "company_formation_certificate.pdf";
    a.click();
  };

  const [dateOfBirth, setDateOfBirth] = useState("");

  const [directorsGovernmentID, setDirectorsGovernmentID] = useState("");

  const handleVerifyPassport = async () => {
    try {
      const response = await axios.post("https://companyprocessuk.com/api/verify-passport", {
        passport,
      });
      setPassportVerify(true);
      toast.success(response.data.message);
    } catch (error) {
      setPassportVerify(false);
      toast.error(error.response.data.message);
    }
  };

  const handleDirectorIdVerify = async () => {
    try {
      const response = await axios.post("https://companyprocessuk.com/api/verify-DGID", {
        passport,
        directorsGovernmentID,
      });
      setDgIdVerify(true);
      toast.success(response.data.message);
    } catch (error) {
      setDgIdVerify(false);
      toast.error(error.response.data.message);
    }
  };

  const handleDOBVerify = async () => {
    // ReactGA.event({ category: "test", action: "submit", label: "label" });
    ReactGA.event({
      category: "Button Click",
      action: "Submit Verification",
      label: "Submit Button",
    });
    try {
      const response = await axios.post("https://companyprocessuk.com/api/verify-bod", {
        passport,
        directorsGovernmentID,
        dateOfBirth,
      });
      handleDownloadPdf();
      setBodVerify(true);
      toast.success(response?.data?.message);
    } catch (error) {
      setBodVerify(false);
      toast.error(error?.response?.data?.message);
    }
  };

  return (
    <>
      <div className="main-header">
        <div className="container br-bottom">
          <div className="header-wrapper">
            <div className="logo-sec">
              {/* <svg
                aria-hidden="true"
                focusable="false"
                className="govuk-header__logotype-crown"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 32 30"
                height="30"
                width="32"
              >
                <path
                  fill="currentColor"
                  fillRule="evenodd"
                  d="M22.6 10.4c-1 .4-2-.1-2.4-1-.4-.9.1-2 1-2.4.9-.4 2 .1 2.4 1s-.1 2-1 2.4m-5.9 6.7c-.9.4-2-.1-2.4-1-.4-.9.1-2 1-2.4.9-.4 2 .1 2.4 1s-.1 2-1 2.4m10.8-3.7c-1 .4-2-.1-2.4-1-.4-.9.1-2 1-2.4.9-.4 2 .1 2.4 1s0 2-1 2.4m3.3 4.8c-1 .4-2-.1-2.4-1-.4-.9.1-2 1-2.4.9-.4 2 .1 2.4 1s-.1 2-1 2.4M17 4.7l2.3 1.2V2.5l-2.3.7-.2-.2.9-3h-3.4l.9 3-.2.2c-.1.1-2.3-.7-2.3-.7v3.4L15 4.7c.1.1.1.2.2.2l-1.3 4c-.1.2-.1.4-.1.6 0 1.1.8 2 1.9 2.2h.7c1-.2 1.9-1.1 1.9-2.1 0-.2 0-.4-.1-.6l-1.3-4c-.1-.2 0-.2.1-.3m-7.6 5.7c.9.4 2-.1 2.4-1 .4-.9-.1-2-1-2.4-.9-.4-2 .1-2.4 1s0 2 1 2.4m-5 3c.9.4 2-.1 2.4-1 .4-.9-.1-2-1-2.4-.9-.4-2 .1-2.4 1s.1 2 1 2.4m-3.2 4.8c.9.4 2-.1 2.4-1 .4-.9-.1-2-1-2.4-.9-.4-2 .1-2.4 1s0 2 1 2.4m14.8 11c4.4 0 8.6.3 12.3.8 1.1-4.5 2.4-7 3.7-8.8l-2.5-.9c.2 1.3.3 1.9 0 2.7-.4-.4-.8-1.1-1.1-2.3l-1.2 4c.7-.5 1.3-.8 2-.9-1.1 2.5-2.6 3.1-3.5 3-1.1-.2-1.7-1.2-1.5-2.1.3-1.2 1.5-1.5 2.1-.1 1.1-2.3-.8-3-2-2.3 1.9-1.9 2.1-3.5.6-5.6-2.1 1.6-2.1 3.2-1.2 5.5-1.2-1.4-3.2-.6-2.5 1.6.9-1.4 2.1-.5 1.9.8-.2 1.1-1.7 2.1-3.5 1.9-2.7-.2-2.9-2.1-2.9-3.6.7-.1 1.9.5 2.9 1.9l.4-4.3c-1.1 1.1-2.1 1.4-3.2 1.4.4-1.2 2.1-3 2.1-3h-5.4s1.7 1.9 2.1 3c-1.1 0-2.1-.2-3.2-1.4l.4 4.3c1-1.4 2.2-2 2.9-1.9-.1 1.5-.2 3.4-2.9 3.6-1.9.2-3.4-.8-3.5-1.9-.2-1.3 1-2.2 1.9-.8.7-2.3-1.2-3-2.5-1.6.9-2.2.9-3.9-1.2-5.5-1.5 2-1.3 3.7.6 5.6-1.2-.7-3.1 0-2 2.3.6-1.4 1.8-1.1 2.1.1.2.9-.3 1.9-1.5 2.1-.9.2-2.4-.5-3.5-3 .6 0 1.2.3 2 .9l-1.2-4c-.3 1.1-.7 1.9-1.1 2.3-.3-.8-.2-1.4 0-2.7l-2.9.9C1.3 23 2.6 25.5 3.7 30c3.7-.5 7.9-.8 12.3-.8"
                ></path> */}
              <img
                src={asset2}
                // display="none"
                className="govuk-header__logotype-crown-fallback-image"
                width="36"
                height="32"
              />
              {/* </svg> */}
              <h2>GOV.UK</h2>
            </div>
            <a href="#">Find and update company information</a>
          </div>
        </div>
      </div>
      <div>
        <div className="container">
          <div className="pass-wrapper">
            <div className="title-sec">
              <h1>Company House Registered Checks</h1>
              {/* <p>
                Company number <strong>14277622</strong>
              </p> */}
            </div>
            <div className="passport-form-wrapper">
              <div className="form-wrapper">
                <div className="input-wrapper">
                  <input
                    type="text"
                    className="cus-input"
                    placeholder="Company Number"
                    value={passport}
                    onChange={(e) => {
                      setPassport(e.target.value);
                    }}
                    autoComplete="off"
                  />
                  <button
                    className="check-btn"
                    color={`${passportVerify ? "success" : "info"}`}
                    onClick={() => {
                      if (!passportVerify) {
                        handleVerifyPassport();
                      }
                    }}
                    disabled={!passport}
                  >
                    {passportVerify ? "Verified" : "Check"}
                  </button>
                </div>
                {passportVerify && (
                  <div className="input-wrapper">
                    <input
                      type="text"
                      className="cus-input"
                      placeholder="Government ID"
                      value={directorsGovernmentID}
                      onChange={(e) => {
                        setDirectorsGovernmentID(e.target.value);
                      }}
                      autoComplete="off"
                    />
                    <button
                      className="check-btn"
                      color={`${dgIdVerify ? "success" : "info"}`}
                      onClick={() => {
                        if (passportVerify) {
                          handleDirectorIdVerify();
                        }
                      }}
                      disabled={!directorsGovernmentID}
                    >
                      {dgIdVerify ? "Verified" : "Check"}
                    </button>
                  </div>
                )}
                {passportVerify && dgIdVerify && (
                  <>
                    <div className="datepicker-main">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={["DatePicker"]}>
                          <DatePicker
                            format="DD/MM/YYYY"
                            className="new-datepicker-input"
                            label="Date of Birth"
                            fullWidth
                            views={["year", "month", "day"]}
                            onChange={(value) => {
                              const formatedDate = moment(value.$d).format("YYYY-MM-DD");
                              setDateOfBirth(formatedDate);
                            }}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </div>
                  </>
                )}

                <div className="submit-btn-sec">
                  <button
                    className="check-btn submit-btn"
                    color="info"
                    disabled={!dateOfBirth || !passport || !directorsGovernmentID}
                    onClick={() => {
                      handleDOBVerify();
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PassportVerify;
